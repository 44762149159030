
import { useContext, createContext } from 'react';
import axios, {AxiosResponse} from 'axios';
import { IdToken } from '@auth0/auth0-react';

import { ConfigInfoService } from './ConfigInfo';

import { OfferWithScope_UserWithoutId } from './DataStructures'

export enum CacheKeys {  
  OFFERS_BY_PROFESSION,
}


export class APIUnauthenticatedService {
  private static instance: APIUnauthenticatedService;
  private API_PATH; 

  private constructor(configInfo: ConfigInfoService) { 
    
    this.API_PATH = configInfo.apiServer;
  }

  public static getInstance = (configInfo: ConfigInfoService) => {

    if (!APIUnauthenticatedService.instance) {
      APIUnauthenticatedService.instance = new APIUnauthenticatedService(configInfo);
    }
    return APIUnauthenticatedService.instance;

  }

  public getApiInfo= async (): Promise<{message:string}> => {
    const response = await axios.get<{message:string}>(`${this.API_PATH}/info`);
    return response.data;
  };

  public getOffersByProfession = async (profession: string): Promise<OfferWithScope_UserWithoutId[]> => {
    const response = await axios.get<OfferWithScope_UserWithoutId[]>(`${this.API_PATH}/offers/profession/${profession}`);
    return response.data;
  };  
  
}


export const APIUnauthenticatedServiceContext = createContext<APIUnauthenticatedService| null>(null);

export function useAPIUnauthenticatedService() {      

    const context = useContext(APIUnauthenticatedServiceContext);

    if (context === null) {
      throw new Error('useAPIUnauthenticatedService must be used within a APIUnauthenticatedServiceContext.Provider');
    }
    return context;

}