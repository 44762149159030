import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { Container, FormControlLabel, Switch, TextField, Button, FormControl, FormHelperText,  InputLabel, Select, MenuItem, Card, CardContent, Typography, Grid, CardHeader } from '@mui/material';
import { toast} from 'react-toastify';
import { useAPIAuthenticatedService  } from '../helpers/APICallsAuthenticated';
import { InHouseCardHeader, InHouseCardContent } from '../components/CustomMUI';
import { UserForcedCreationAttributes, CacheKeys } from '../helpers/DataStructures';

const ForceUserForm: React.FC = () => {
  const { handleSubmit, control, formState: { errors }, reset } = useForm<UserForcedCreationAttributes>();
  const queryClient = useQueryClient();

  const apiServiceInstance = useAPIAuthenticatedService();

//   const { isLoading, isError, data: data_loggedInUser, error } = useQuery(
//     [CacheKeys.LOGGED_IN_USER], 
//     async () => {
      
//         const user = await apiServiceInstance.getAuthenticatedUser();
//         return user;      
//     }
//   ); 

  const { mutateAsync, isLoading, isError, isSuccess, error } = 
    useMutation([CacheKeys.OFFERS_BY_PROFESSION_AUTHENTICATED], apiServiceInstance.forceAuthenticatedUser,
    {
        onSuccess: () => {
            queryClient.invalidateQueries([CacheKeys.OFFERS_BY_PROFESSION_AUTHENTICATED]);
            reset();
            toast.success("User Created Successfully!");
        },
        onError: (error: any) => {
            toast.error(`An error occurred: ${error.response?.data?.message || error.message}`);
        }
    },
  );

  const onSubmit = (data: UserForcedCreationAttributes) => {
    mutateAsync({ ...data });
  };

  return (
   
        <Card variant="outlined"  style={{ margin: '0 auto' }}>
            <InHouseCardContent>     
               
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs={12}>
                            <Controller                                
                                name="givenName"
                                control={control}
                                defaultValue=""
                                rules={{ required: 'First name is required', maxLength: { value: 255, message: 'First name should be less than 255 characters'} }}
                                render={({ field }) => <TextField {...field} label="First Name" error={Boolean(errors.givenName)} helperText={errors.givenName?.message} fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="familyName"
                                control={control}
                                defaultValue=""
                                rules={{ required: 'Last name is required', maxLength: { value: 255, message: 'Last name should be less than 255 characters'} }}
                                render={({ field }) => <TextField {...field} label="Last Name" error={Boolean(errors.familyName)} helperText={errors.familyName?.message} fullWidth/>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                rules={{ required: 'email is required', maxLength: { value: 255, message: 'email should be less than 255 characters'} }}
                                render={({ field }) => <TextField {...field} label="Email" error={Boolean(errors.email)} helperText={errors.email?.message} fullWidth/>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="profession"
                                control={control}
                                defaultValue=""
                                rules={{ required: 'Profession is required' }}
                                render={({ field }) => (
                                <FormControl fullWidth>
                                    <InputLabel id="profession-label">Profession</InputLabel>
                                    <Select
                                    {...field}
                                    labelId="profession-label"
                                    label="Profession"
                                    error={Boolean(errors.profession)}
                                    > 
                                    <MenuItem value="estate agent">Estate Agent</MenuItem>
                                    <MenuItem value="surveyor">Surveyor</MenuItem>
                                    <MenuItem value="conveyancer">Conveyencer</MenuItem>
                                    <MenuItem value="mortgage broker">Mortgage Broker</MenuItem>
                                    </Select>
                                    {errors.profession && <FormHelperText error>{errors.profession.message}</FormHelperText>}
      
                                </FormControl>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="companyName"
                                control={control}
                                defaultValue=""
                                
                                rules={{ maxLength: { value: 255, message: 'Company name should be less than 255 characters'} }}
                                render={({ field }) => <TextField {...field} label="Company Name" error={Boolean(errors.companyName)} helperText={errors.companyName?.message} fullWidth/>}
                            />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <Controller
                                name="createOffer"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => 
                                    <FormControlLabel
                                        control={<Switch {...field} />}
                                        label="Create Offer"
                                    />
                                }
                            />
                        </Grid>

                        {/* <Grid item xs={12}>
                            <Controller
                                name="sendEmail"
                                control={control}
                                defaultValue={false}
                                render={({ field }) =>
                                    <FormControlLabel
                                        control={<Switch {...field} />}
                                        label="Send Email"
                                    />
                                }
                            />
                        </Grid> */}

                        <Grid item xs={12}  sx={{textAlign:'center'}}>
                            <Button type="submit" disabled={isLoading}>Submit</Button>
                        </Grid>
                    </Grid>
                </form>
            </InHouseCardContent>
        </Card>
    
   
  );
};

export default ForceUserForm
