// axiosInstance.ts
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { IdToken } from '@auth0/auth0-react';

export default class AxiosService {
  
  private static instance: AxiosService;
  public use: AxiosInstance;

  private getAccessTokenSilently:() => Promise<string>;
  private getIdTokenClaims: () => Promise<IdToken | undefined>;

  public idToken: IdToken | undefined = undefined;

  private constructor(getAccessTokenSilently: () => Promise<string>, getIdTokenClaims: () => Promise<IdToken | undefined>) {
    // Create a new Axios instance
    this.use = axios.create();
    this.getAccessTokenSilently = getAccessTokenSilently; 
    this.getIdTokenClaims = getIdTokenClaims;
    
  }

  public getEmail = () => this.idToken?.email ?? undefined;

  public init = async () => {
    const accessToken = await this.getAccessTokenSilently();
    this.idToken = await this.getIdTokenClaims();

    this.setAuthToken(accessToken);
    this.setupInterceptor(this.getAccessTokenSilently);
  }

  public static getInstance(getAccessTokenSilently: () => Promise<string>, getIdTokenClaims: () => Promise<IdToken | undefined>) {
    if (!AxiosService.instance) {
      AxiosService.instance = new AxiosService(getAccessTokenSilently, getIdTokenClaims);
    }
    return AxiosService.instance;
  }
  

  private setAuthToken(token: string | null) {
    if (token) {
      // Apply it to every request header
      this.use.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      // Remove the Authorization header
      delete this.use.defaults.headers.common['Authorization'];
    }
  }

  private setupInterceptor(getAccessTokenSilently: () => Promise<string>) {
    // This function will be called before each request is sent and after each response is received
    this.use.interceptors.response.use(
      (response: AxiosResponse) => {
        // If the response is successful, just return the response
        return response;
      },
      async (error: AxiosError) => {
        // If there's an error in the response...

        if (error.response) {
          // If the error has a response (meaning the request was made but the server responded with a status outside of the range of 2xx)
          const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };

          // If the status is 401 (Unauthorized) and it's the first attempt to request...
          if (error.response.status === 401 && !originalRequest._retry && originalRequest.headers) {
            originalRequest._retry = true; // Mark the request as retried
            try {
              const token = await getAccessTokenSilently(); // Get a new token silently
              this.setAuthToken(token); // Set the token for future requests

              originalRequest.headers['Authorization'] = `Bearer ${token}`; // Set the token in the original request

              return this.use(originalRequest); // Retry the request with the new token
            } catch (err) {
              // If there's an error while refreshing the token, reject the Promise with the error
              return Promise.reject(err);
            }
          }
          else {
            // If it's not a 401 error or it's a retry, reject the Promise with the original error
            return Promise.reject(error);
          }
        } else {
          // If the error doesn't have a response (meaning the request was made but no response was received), reject the Promise with the error
          return Promise.reject(error);
        }
      }
    );
  }
}
