


export function translate(word: string): string {

    const dictionary: {[key: string]: string} = {
        "created": "Sent",
        "succeeded": "Successful",
        "failed": "Unsuccessful",
        "payoutrequested": "Balance Requested",
    };

    const lowerCaseWord = word.toLowerCase();

    if (lowerCaseWord in dictionary) {
        return dictionary[lowerCaseWord];
    } else {
        return word;
    }
}

  