import React, {useState} from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, DialogActions, Dialog, DialogTitle, IconButton, DialogContent, Container, Card, CardHeader, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { useAPIAuthenticatedService } from '../../helpers/APICallsAuthenticated';
import { ReferralWithScope_UserWithoutId, CacheKeys } from '../../helpers/DataStructures';
import DateTimeFormat from '../../helpers/DateTimeFormat';

import CloseIcon from '@mui/icons-material/Close';

import { translate } from '../../helpers/Language';

import { InHouseCardHeader, InHouseCardContent } from '../../components/CustomMUI';

import { toast } from 'react-toastify';

import DisplayReferralIn from '../../components/DisplayReferralIn';

const ReferralsAppReferralsIn: React.FC = () => {

  const apiServiceInstance = useAPIAuthenticatedService();  
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [selectedReferral, setSelectedReferral] = useState<ReferralWithScope_UserWithoutId | null>(null);

  const handleOpen = (referral: ReferralWithScope_UserWithoutId) => {
    setSelectedReferral(referral);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const { isLoading, isError, data, error } = useQuery(
    [CacheKeys.REFERRALS_TO_USER],
    () => apiServiceInstance.getUserReferralsIn(),
    { keepPreviousData: true }
  );

   const setUserReferralsInStatus = async ({ referralId, newStatus }: {referralId: number, newStatus: string}) => {
    return apiServiceInstance.setUserReferralsInStatus(referralId, newStatus);
  };

  const updateStatusMutation = useMutation(setUserReferralsInStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([CacheKeys.REFERRALS_TO_USER]);
      toast.success("Referral Updated");
      handleClose(); // Close the dialog
    },
    onError: (error: any) => {
      toast.error(`An error occurred: ${error.response?.data?.message || error.message}`);
    },
  });

  const handleUpdateStatus = (newStatus: string) => {

    if(selectedReferral)
      updateStatusMutation.mutate({ referralId: selectedReferral.id, newStatus });
  };

  return (

    <>
    
      <Card variant="outlined" title='dd' style={{ margin: '0 auto' }}>
       
              <InHouseCardHeader title='Referrals Received'  />  
              <InHouseCardContent style={{ textAlign: 'center' }} > 

              { 
                isLoading || data === undefined  ? 
                (
                    <>ff</>
                ) : data && data?.length === 0 ?
                (
                    <Typography variant='body2'>No referrals received yet</Typography>
                ) :
                (
                    <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell>From</TableCell>
                            <TableCell>Sent</TableCell>
                            <TableCell>Customer</TableCell>
                            <TableCell>Contact</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {data.map((referral: ReferralWithScope_UserWithoutId) => (
                            <TableRow key={referral.id} onClick={() => handleOpen(referral)} style={{ cursor: 'pointer' }}>
                            <TableCell>{referral.user.givenName} {referral.user.familyName}</TableCell>
                            <TableCell>{DateTimeFormat(referral.offer.startDateTime)}</TableCell>
                            <TableCell>{referral.theReferralName}</TableCell>
                            <TableCell>{referral.theReferralContact}</TableCell>
                            <TableCell>{translate(referral.status)}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                )
            }

             
                  
              </InHouseCardContent>               
                          
      </Card>

      { selectedReferral && (
          <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DisplayReferralIn referral={selectedReferral} />
          </DialogContent>

          { selectedReferral.status === 'created' && (

            <DialogActions>
            <Button onClick={() => handleUpdateStatus('succeeded')} color="primary">
                Referral was successful
            </Button>
            <Button onClick={() => handleUpdateStatus('failed')} color="secondary">
                Referral was unsuccessful
            </Button>
            </DialogActions>

        )}

        </Dialog>
        )}

        </>
    
  );
};

export default ReferralsAppReferralsIn;
