import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Fab from '@mui/material/Fab';

import { NavLink as RouterNavLink, useLocation } from 'react-router-dom';

import { useAuth0 } from "@auth0/auth0-react";

import { useConfigInfoService  } from '../helpers/ConfigInfo';

const pages = [
  { name: 'Home', path: '/' },
  { name: 'Dashboard', path: '/dashboard' },
  { name: 'About', path: '/about-us' },
  { name: 'Contact', path: '/contact' },
  { name: 'T&Cs', path: '/terms-and-conditions' },
];

const NavBar: React.FC = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const configInfoServiceInstance = useConfigInfoService();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
    });
  
    const location = useLocation();
    const currentTab = pages.findIndex(page => page.path === location.pathname) || 0;
  

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: location.pathname === '/' ? 'none' : { xs: 'flex', md: 'none' }, mr: 1 }}>
            <img alt="logo" src="/logo_g.png" style={{ height: 50, width: 50 }} />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={handleCloseNavMenu}
                  component={RouterNavLink}
                  to={page.path}
                >
                  <Typography>{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ display: location.pathname === '/' ? 'none' : { xs: 'none', md: 'flex' }, mr: 1 }}>
            <img alt="logo" src="/logo_g.png" style={{ height: 50, width: 50 }} />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>


          <Tabs value={currentTab === -1 ? 0 : currentTab} aria-label="navigation tabs">            
            {pages.map((page, index) => (              
              <Tab
                key={page.name}
                component={RouterNavLink}
                to={page.path}
                label={page.name}
                value={index}
              />            
            ))}
            </Tabs>
           
          </Box>
          {isAuthenticated && user && configInfoServiceInstance.environment === 'test' && (
            <Box sx={{ flexGrow: 0, marginRight: '10px' }}>
              <Typography variant='button'>TEST SITE</Typography>              
            </Box>
          )}
          {isAuthenticated && user && (
            <Box sx={{ flexGrow: 0 }}>            
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Profile" src={user.picture} />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={() => logoutWithRedirect()}>
                  <Typography>Logout</Typography>
                </MenuItem>
              </Menu>
             
            </Box>
          )}
           { !isAuthenticated && location.pathname !== '/dashboard' && (

                // <Button  
                // style={{ width: '95%', marginTop: '20%' }} 
                // onClick={() => loginWithRedirect({  
                //     appState: { 
                //         returnTo: window.location.href,
                //         redirectUri: window.location.origin + window.location.pathname
                //       }
                //   })} 
                // color='primary'
                // variant="contained" >

                //     Log in
                // </Button>
                <Fab  
                  variant="extended" 
                  size="small" 
                  color="primary" 
                  aria-label="log in"
                  onClick={() => loginWithRedirect({  
                    appState: { 
                        returnTo: window.location.href,
                        redirectUri: window.location.origin + window.location.pathname
                      }
                  })} >
                  {configInfoServiceInstance.environment === 'test' ? 'Test Log In' : 'Log in'}
                </Fab>

           )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavBar;
