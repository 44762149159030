import React, {useEffect, useState} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { TextField, Card, CardContent, Typography, Grid, Button, Box, Checkbox, FormControlLabel, IconButton, InputAdornment } from '@mui/material';
import { toast } from 'react-toastify';
import { useAPIAuthenticatedService } from '../helpers/APICallsAuthenticated';
import { CacheKeys, OfferCreationAttributes_FromUser } from '../helpers/DataStructures';


import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface CreateOfferFormProps {
  extraButton?: JSX.Element;
  mode: "Edit" | "Create"; 
}

const CreateOfferForm: React.FC<CreateOfferFormProps> = ({extraButton, mode}) => {

  const apiServiceInstance = useAPIAuthenticatedService();

  const { isLoading: isLoading_UsersOffer, isError: isError_UsersOffer, data: data_UsersOffer, error: error_UsersOffer } = useQuery(
    [CacheKeys.LOGGED_IN_USERS_OFFER], 
    async () => {
      const user = await apiServiceInstance.getOffersForAuthenticatedUser();
      return user;
    }
  );

  const { handleSubmit, control, formState: { errors }, getValues, setValue } = useForm<OfferCreationAttributes_FromUser>({
    defaultValues: {
      theOffer: data_UsersOffer ? data_UsersOffer.theOffer : "",
      location: data_UsersOffer ? data_UsersOffer.location : "",
      offeringFixedCashAmount: data_UsersOffer ? data_UsersOffer.offeringFixedCashAmount : 50,
    }
  });

  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isError, isSuccess, error } = 
    useMutation([CacheKeys.LOGGED_IN_USERS_OFFER], apiServiceInstance.createOffer,
    {
        onSuccess: () => {
            queryClient.invalidateQueries([CacheKeys.LOGGED_IN_USERS_OFFER]);
            //toast.success("Offer Created Successfully!");
        },
        onError: (error: any) => {
            toast.error(`An error occurred: ${error.response?.data?.message || error.message}`);
        }
    },
  );

  const onSubmit = (data: OfferCreationAttributes_FromUser) => {
    mutateAsync({ ...data });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
            <Controller
                name="offeringFixedCashAmount"
                control={control}
                rules={{ 
                required: 'Cash amount is required',
                min: {value: 0, message: "Can't offer less than zero"},
                max: {value: 995, message: "Maximum amount is £995"} 
                }}
                render={({ field }) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField 
                    {...field} 
                    label="How much will you offer?" 
                    error={Boolean(errors.offeringFixedCashAmount)} 
                    helperText={errors.offeringFixedCashAmount?.message} 
                    fullWidth 
                    InputProps={{
                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    }}
                    />
                    <IconButton 
                        size="small"
                        onClick={() => {
                            const newValue = Math.min(getValues("offeringFixedCashAmount") + 5, 995);
                            setValue("offeringFixedCashAmount", newValue);
                        }}
                        disabled={getValues("offeringFixedCashAmount") >= 995}
                    >
                        <AddIcon />
                    </IconButton>
                    <IconButton 
                    size="small"
                    onClick={() => {
                        const newValue = Math.max(getValues("offeringFixedCashAmount") - 5, 5);
                        setValue("offeringFixedCashAmount", newValue);
                    }}
                    disabled={getValues("offeringFixedCashAmount") <= 5}
                    >
                        <RemoveIcon />
                    </IconButton>
                </div>
                )}
            />
        </Grid>

        <Grid item xs={12}>
            <Controller
            name="location"
                control={control}
                rules={{ required: 'Area is required' }}
                render={({ field }) => <TextField {...field} label="What area do you cover?" error={Boolean(errors.location)} 
                
                //helperText={errors.location?.message} 
                helperText={errors.location?.message} 
                
                fullWidth />}
            />
        </Grid>

            <Grid item xs={12}>
            <Controller
                name="theOffer"
                control={control}
                rules={{ maxLength: 1000 }}
                render={({ field }) => 
                <TextField multiline rows={3} {...field} 
                  label="Services you provide" 
                  error={Boolean(errors.theOffer)} 
                  helperText={errors.theOffer?.message || (errors.theOffer?.type === "maxLength" && "text exceeded maximum length")}      
                  fullWidth />}
            />
        </Grid>

      <Grid item xs={12}>
        <Button type="submit">Save</Button> {extraButton}
      </Grid>
      </Grid>
    </form>
  );
};

export default CreateOfferForm;
