import TextField, { TextFieldProps } from '@mui/material/TextField';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';

import { alpha, styled } from '@mui/material/styles';

import { Typography, CardHeader,CardContent } from '@mui/material';
import InputBase from '@mui/material/InputBase';

import Button from '@mui/material/Button';

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 12,
      position: 'relative',
      backgroundColor: '#fff',
      color: '#000',
      border: '2px solid',
      borderColor: theme.palette.primary.dark,  // set default border color
      fontSize: 16,
      //width: 'auto',
      padding: '10px 12px',
      transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.2)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main, // change border color when focused
      },
    },
  }));

  export const InHouseCardHeader = styled(CardHeader)(({ theme }) => ({
    textAlign: 'center',   
    backgroundColor: theme.palette.grey[800], 

    [theme.breakpoints.up('xs')]: { margin: '0px' },
    [theme.breakpoints.up('sm')]: { margin: '5px' },
    [theme.breakpoints.up('md')]: { margin: '10px' },

    padding: '5px',
    titleTypographyProps: { variant:'h3', color: theme.palette.grey[50] }
  }));

  export const InHouseCardContent= styled(CardContent)(({ theme }) => ({
   
    [theme.breakpoints.up('xs')]: { padding: '2px' },
    [theme.breakpoints.up('sm')]: { padding: '5px' },
    [theme.breakpoints.up('md')]: { padding: '20px' },
  
  }));

  // titleTypographyProps={{ variant:'h3'}}  
  //             title={`${data_User.givenName} ${data_User.familyName}`} 
  //             subheader={`${data_User.profession}${ !isDataEmpty(data_User.companyName) ? ` at ${data_User.companyName}` : ''}`}
  //             sx={{textAlign: 'center', backgroundColor: '#333', margin: '10px', padding: '5px' }}

  // export const BigGreenTypography = styled(Typography)(({ theme }) => ({
  //   color: theme.palette.primary.main,    
  //   fontSize: "1.8rem",
  // }));

  export const BigGreenSpacedTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,    
    fontSize: "1.8rem",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.2rem",
    },
    marginBottom: "30px"
  }));

  export const EasyReadTypography = styled(Typography)(({ theme }) => ({    
    //fontSize: "1.2rem",
    //lineHeight: '3',
    margin: '50px 50px 50px 10px',

    [theme.breakpoints.down('sm')]: {
      //fontSize: "1.0rem",
      //lineHeight: '2',
      margin: '25px 25px 25px 5px',
    },
}));
  
  // export const OutlinedButton = styled(Button)(({ theme }) => ({
  //   variant: "contained",
  //   color: "secondary",
  //   //color: theme.palette.getContrastText(theme.palette.primary.main),
  //   //backgroundColor: theme.palette.primary.main,
  //   // '&:hover': {
  //   //   //backgroundColor: theme.palette.primary.dark,
  //   // },
  //   border: `2px solid ${}`,
  //   // transition: theme.transitions.create(['background-color', 'border-color']),
  //   // '&:focus': {
  //   //   borderColor: theme.palette.primary.light,
  //   //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
  //   // },
  // }));