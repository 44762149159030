import React, { SyntheticEvent, MouseEvent, useState } from "react";
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import CookieIcon from '@mui/icons-material/Cookie';

function CookieConsent() {
  const [open, setOpen] = useState(!Cookies.get('cookieConsent'));

  const handleClose = (event: Event | SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    // Set the cookie when user accepts
    Cookies.set('cookieConsent', 'accepted', { expires: 365 });
    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        action={
          <Button color='warning' variant='contained' size='small' onClick={handleClose}>
            I Understand
          </Button>
        }      
         message={
          <Box display="flex" alignItems="center">
             <CookieIcon sx={{fontSize: '50px', margin: '10px'}} />
          <Box>
            <Typography variant='body2'>We use Google Analytics cookies in order to improve the user experience.</Typography> 
            <Typography variant='body2'>These cookies are third-party cookies.</Typography>
            <Typography variant='body2'>You can find out more about these cookies by reading our 
              <a href='/info/privacy' style={{color: 'white'}}> privacy policy</a>.
            </Typography>
            <Typography variant='body2'>By clicking "I Understand", you consent to our use of these cookies.</Typography>
          </Box>
        </Box>

        }
      />
    </div>
  );
}

export default CookieConsent;
