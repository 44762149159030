import React, { useState } from 'react';
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import {
  Grid,
  Card,
  IconButton,
  Typography,
  Button,
  Link,
  Box,
  useTheme
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';


const Footer: React.FC = () => {

  const theme = useTheme();
  
  return (
    <Card sx={{ marginTop: '30px', padding: '10px' }}>        
        <Box width={'100%'}  textAlign={'center'} >
            <Typography variant='bigPrimary_body2'>In House Property Hub</Typography>
            <Typography variant='body2' textAlign={'center'} >
                Part of the Alenzo Ltd Group<br/>
                Registered Office<br/>
                20 Wenlock Road<br/>
                London<br/>
                N1 7GU<br/>
                United Kingdom<br/>
                Company Number: 14888390<br/>
            </Typography>
        </Box>
        <Box textAlign={'right'} width={'100%'} marginTop={{xs:'25px', md: '0px'}}>
            <Typography variant='body2'>Copywrite © Alenzo™ 2023</Typography>
        </Box>
    </Card>
  );
};

export default Footer;
