
import { useContext, createContext } from 'react';


export class ConfigInfoService {
  private static instance: ConfigInfoService;

  public environment: 'live'|'test';

  public apiServer: string;

  private constructor() {     
  
    if (process.env.REACT_APP_API_PATH_PROD) {

      console.warn("%cWARNING: Connecting to live environment", "color: orange; font-size: 20px");
      this.environment = 'live';
      this.apiServer = process.env.REACT_APP_API_PATH_PROD;

    } else if (process.env.REACT_APP_API_PATH_TEST) {

      console.log("%cNOTICE: Connecting to test environment", "color: green; font-size: 20px");      
      this.environment = 'test';
      this.apiServer = process.env.REACT_APP_API_PATH_TEST;
    }
    else if (process.env.REACT_APP_API_PATH_DEV) {

        console.log("%cNOTICE: Connecting to dev environment", "color: green; font-size: 20px");      
        this.environment = 'test';
        this.apiServer = process.env.REACT_APP_API_PATH_DEV;
    }
    else
    {
        throw new Error("REACT_APP_API_PATH_PROD, REACT_APP_API_PATH_TEST and REACT_APP_API_PATH_DEV are undefined");
    }

    console.log("React App Version: " + process.env.REACT_APP_VERSION);
  
  }

  public static getInstance = () => {
    if (!ConfigInfoService.instance) {
        ConfigInfoService.instance = new ConfigInfoService();
    }

    return ConfigInfoService.instance;
  }

 
 
}


export const ConfigInfoServiceContext = createContext<ConfigInfoService| null>(null);

export function useConfigInfoService() {     

    const context = useContext(ConfigInfoServiceContext);

    if (context === null) {
      throw new Error('useConfigInfoService must be used within a ConfigInfoServiceContext.Provider');
    }
    return context;
  }