import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Container, Card, CardHeader, CardContent, Typography } from '@mui/material';
import { toast } from 'react-toastify';

import { useAPIAuthenticatedService } from '../../helpers/APICallsAuthenticated';
import {  CacheKeys } from '../../helpers/DataStructures';

import CreateOfferForm from '../../forms/CreateOfferForm'; // assuming this is the correct import path

const ReferralsAppOfferManagment: React.FC = () => {
  const apiServiceInstance = useAPIAuthenticatedService();

  const { isLoading, isError, data, error } = useQuery(
    [CacheKeys.LOGGED_IN_USERS_OFFER],
    async () => {
      const user = await apiServiceInstance.getOffersForAuthenticatedUser();
      return user;
    }
  );

  useEffect(() => {    
    if (data) {
      setEditMode(false);
    }
  }, [data]);

  const queryClient = useQueryClient();

  const mutation = useMutation(() => apiServiceInstance.withdrawOfferForLoggedInUser(), {
    onSuccess: () => {
      queryClient.invalidateQueries([CacheKeys.LOGGED_IN_USERS_OFFER]);
      toast.success("Offer withdrawn successfully");
    },    
    onError: (error: any) => {
      toast.error(`An error occurred: ${error.response?.data?.message || error.message}`);
    }
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleWithdraw = () => {
    setDialogOpen(true);
  };

  const handleConfirmWithdraw = () => {
    mutation.mutate();
    setDialogOpen(false);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  return (
    
    
        <Card variant="outlined" title='dd' style={{ margin: '0 auto' }}>
         
            {
            isLoading ? 
            (
              <>
                <CardHeader title='Loading...' sx={{textAlign: 'center', backgroundColor: '#333', margin: '10px', padding: '2px' }}>
                  
                </CardHeader>
                
              </>
            ) 
            : isError ?
            (
              <>
              <CardHeader title='Error' sx={{textAlign: 'center', backgroundColor: '#333', margin: '10px', padding: '2px' }}>
                
              </CardHeader>
              
            </>
            ) 
            : !data ? 
            (
              <>              
                <CardHeader title="Create an offer" sx={{textAlign: 'center', backgroundColor: '#333', margin: '10px', padding: '2px' }}>
                  
                </CardHeader>
                <CardContent>
                  <CreateOfferForm mode="Create"/>
                </CardContent>    
              
              </>
            ) 
            : 
            ( 
              <> 
                  <CardHeader title='Your Offer' sx={{textAlign: 'center', backgroundColor: '#333', margin: '10px', padding: '2px' }}>
                  </CardHeader>   
                  <CardContent style={{ textAlign: 'center' }} >         
                  {!editMode && 
                  (
                      <>
                        { 
                          data.offeringFixedCashAmount == 0 && 
                          <Typography variant='body2' mb={2}>You are not currently offering a reward for successful referrals</Typography>                        
                        } 
                        { 
                          data.offeringFixedCashAmount > 0 && 
                          <Typography variant='body2' mb={2}>You are offering <b>£{data.offeringFixedCashAmount}</b> for each successful referral you receive</Typography>                        
                        }
                        
                        <Typography variant='body2'>Area covered:</Typography>    
                        <Typography variant='body1' mb={2}>{data.location}</Typography>
                        
                        { data.theOffer && (<Typography variant='body2' mb={2}>{data.theOffer}</Typography>)}  
                        
                        <Button onClick={handleEdit}>Change your offer</Button> 
                        <Button onClick={handleWithdraw}>Suspend your offer</Button>                      

                      </>
                  )}
                  
                  {editMode && (                  
                      <>                        
                        <CreateOfferForm extraButton={<Button onClick={handleCancelEdit}>Cancel</Button>} mode="Edit" />
                      </>
                  )}

                  </CardContent>

                  <Dialog
                      open={dialogOpen}
                      onClose={() => setDialogOpen(false)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                  >
                      <DialogTitle id="alert-dialog-title">{"Confirm Withdrawal"}</DialogTitle>
                      <DialogContent>
                      Are you sure you want to withdraw your offer?
                      </DialogContent>
                      <DialogActions>
                      <Button onClick={() => setDialogOpen(false)} color="primary">
                          Cancel
                      </Button>
                      <Button onClick={handleConfirmWithdraw} color="primary" autoFocus>
                          Confirm
                      </Button>
                      </DialogActions>
                  </Dialog>
                
              </>

            )
      //) 
      // :
      // (         
      //     <>
      //     <CardHeader title='Please create an offer' sx={{textAlign: 'center', backgroundColor: '#333', margin: '10px', padding: '2px' }}>
            
      //     </CardHeader>
      //     <CardContent>
      //       <CreateOfferForm mode="Create"/>
      //     </CardContent>
      //     </>
        
      // )
      }
            
      </Card>
    
    
  );
};

export default ReferralsAppOfferManagment;
