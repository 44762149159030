
function nth(n: number): string {
    if (n > 3 && n < 21) return 'th'; 
    switch (n % 10) {
          case 1:  return 'st';
          case 2:  return 'nd';
          case 3:  return 'rd';
          default: return 'th';
    }
  }
  
  export default function formatDate(isoString: string): string {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const date = new Date(isoString);
    return days[date.getDay()] + " " + date.getDate() + nth(date.getDate()) + " " + months[date.getMonth()];
  }