import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { css } from '@emotion/react';
import PuffLoader  from 'react-spinners/PuffLoader';
import { useTheme,  } from '@mui/material/styles';
import { Container, Box, Button } from '@mui/material';
import { motion, usePresence } from 'framer-motion';

const Login: React.FC = () => {  
    
    const [isPresent, safeToRemove] = usePresence();
    const theme = useTheme();

    const { getAccessTokenSilently, isAuthenticated, user, loginWithRedirect } = useAuth0();
  
    useEffect(() => {
        if(safeToRemove) safeToRemove();   
        
    }, [safeToRemove]);

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>        
          <Box sx={{ width: '25%', minWidth: '200px', maxWidth: '800px', height: 'auto', position: 'relative', marginTop: '10%' }}>
            <img src='/logo_tag.png' alt='In House logo' style={{ width: '100%', height: 'auto' }} />
            <Button
              style={{ width: '95%', marginTop: '20%' }}              
              color='primary'
              variant="contained"
              onClick={() => loginWithRedirect({
                appState: {
                    returnTo: window.location.href,
                    redirectUri: window.location.origin + window.location.pathname
                  }
              })} >                
              Log in
            </Button>
            <Button
              style={{ width: '95%', marginTop: '10px' }}              
              color='primary'
              onClick={() => loginWithRedirect({ 
                authorizationParams: {
                  screen_hint: 'signup',
                }, 
                appState: {           
                    returnTo: window.location.href,
                    redirectUri: window.location.origin + window.location.pathname
                }
              })} >                
              Sign Up
            </Button>
          </Box>
      </Container>
  );
};

export default Login;
