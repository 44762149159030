import React from 'react';
import { Button, Container, Card, CardHeader, CardContent,  } from '@mui/material';

import { usersScreenSelection } from './ReferralsAppAuthenticated';

interface ScreenSelectedProps {
  titleText: string;
  screenSelection: usersScreenSelection;
  setScreenSelection: (val: usersScreenSelection) => void;
  children: React.ReactNode;
}

const ScreenSelected: React.FC<ScreenSelectedProps> = ({titleText, screenSelection, setScreenSelection, children}) => {
    
    return (
      <>       
        <Container maxWidth="sm" style={{ margin: '5px auto', padding: '0' }}>
          <Card variant="outlined">
          <CardHeader 
            //titleTypographyProps={{ variant:'h3'}}  
            title={titleText}             
            //sx={{textAlign: 'center', backgroundColor: '#333', margin: '10px', padding: '5px' }}
            action={
              <Button 
                variant="contained" 
                color="primary" 
                size='small'
                onClick={() => { setScreenSelection(usersScreenSelection.hello) }}
              >
                Back
              </Button>
            }
          />
            <CardContent>
              
                {children}

            </CardContent>
          </Card>
        </Container>
      </>
    
    );  
};

export default ScreenSelected;
