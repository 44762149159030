import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Card, CardContent, Typography, Grid, Button, Box, Checkbox, FormControlLabel } from '@mui/material';
import { toast } from 'react-toastify';
import { useAPIAuthenticatedService } from '../helpers/APICallsAuthenticated';
import { CacheKeys } from '../helpers/DataStructures';

import { TextToTypography } from '../pages/TextPage';

import { InHouseCardHeader, InHouseCardContent } from '../components/CustomMUI';

type FormValues = {
    agree: boolean;
};

const TandCForm: React.FC = () => {

const { handleSubmit, register, formState: { errors } } = useForm<FormValues>();

const apiServiceInstance = useAPIAuthenticatedService();
const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isError, isSuccess, error } = 
    useMutation([CacheKeys.LOGGED_IN_USER], apiServiceInstance.updateUserAgreementStatus,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CacheKeys.LOGGED_IN_USER]);
        //toast.success("User agreement status updated successfully!");
      },
      onError: (error: any) => {
        toast.error(`An error occurred: ${error.response?.data?.message || error.message}`);
      }
    },
  );  

  const onSubmit = (data: { agree: boolean }) => {
    if (data.agree) {
      mutateAsync();
    } else {
      toast.error('You must agree to the Terms and Conditions.');
    }
  };  

  return (
    <Card variant="outlined"  style={{ margin: '0 auto' }}>
      <InHouseCardHeader title="Terms and Conditions" />
        <InHouseCardContent>      
          <Typography variant="cardSubtitle" component="div" style={{ marginBottom: '1em' }}>
          Please read our Terms and Conditions
          </Typography>   
          
            <Box
              height="200px" 
              overflow="auto"
              maxWidth="550px"
              border={1}
              padding={1}
              marginY={2}
            >
              <TextToTypography filename='text/terms-and-conditions.txt'/>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...register('agree', { required: 'You must agree to the Terms and Conditions.' })}
                            />
                          }
                          label="I agree to the Terms and Conditions"
                        />
                        {errors.agree && <div>{errors.agree.message}</div>}
                    </Grid>
                    <Grid item xs={12}  sx={{textAlign:'center'}}>
                        <Button type="submit" disabled={isLoading}>Submit</Button>
                    </Grid>
                </Grid>
            </form>
        </InHouseCardContent>
    </Card>
  );
};

export default TandCForm;
