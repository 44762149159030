import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const SignUp: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const signUpEmail = params.get('signUpEmail');

  useEffect(() => {
    
    if(!signUpEmail || !loginWithRedirect)
        return;

    loginWithRedirect({
        authorizationParams: {
            screen_hint: 'signup',
            login_hint: signUpEmail,
        },
        appState: {
            returnTo: window.location.origin + '/dashboard',
            redirectUri: window.location.origin + '/dashboard',
        },
    });
  }, [signUpEmail,loginWithRedirect]);

  // rest of your component...
  return <></>;
};


export default SignUp;