import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { useQuery } from '@tanstack/react-query';
import { Box, Grid, Tab, Tabs, TableSortLabel, Button, Dialog, DialogTitle, DialogContent, DialogActions, Container, Card, CardHeader, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toast } from 'react-toastify';
import {  APIUnauthenticatedService, useAPIUnauthenticatedService } from '../../helpers/APICallsUnauthenticated';
import { isDataEmpty, CacheKeys, OfferWithScope_UserWithoutId } from '../../helpers/DataStructures';

import {DisplayOfferInGridBody, DisplayOfferInGridHeader}  from '../../components/DisplayOfferInGrid'

import { InHouseCardHeader } from '../../components/CustomMUI';

const ReferralsAppUnauthenticatedMakeReferral: React.FC = () => {
    
    return (
      <>      
      
          <Card variant="outlined">
          <InHouseCardHeader 
            //titleTypographyProps={{ variant:'h3'}}  
            title='Make a Referral'             
            //sx={{textAlign: 'center', backgroundColor: '#333', margin: '10px', padding: '5px' }}            
          />
            <CardContent>              
                  <AllProfessionsAccordian />
            </CardContent>
          </Card>
        
      </>
    
    );  
};

const AllProfessionsAccordian: React.FC = () => {

  const [selectedProfession, setSelectedProfession] = useState("none");

  const handleChangeProfession = (newValue: string) => {
    setSelectedProfession(newValue === selectedProfession ? "none" :  newValue);
  };

  return (
    <> 
          <CardContent>
            <Accordion expanded={selectedProfession === 'estate agent'} onChange={() => handleChangeProfession('estate agent')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Estate Agents</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ProfessionOffersTable selectedProfession="estate agent"  />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={selectedProfession === 'surveyor'} onChange={() => handleChangeProfession('surveyor')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Surveyors</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ProfessionOffersTable selectedProfession="surveyor"   />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={selectedProfession === 'mortgage broker'} onChange={() => handleChangeProfession('mortgage broker')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Mortgage Brokers</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ProfessionOffersTable selectedProfession="mortgage broker"   />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={selectedProfession === 'conveyancer'} onChange={() => handleChangeProfession('conveyancer')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Conveyancers</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ProfessionOffersTable selectedProfession="conveyancer"  />
              </AccordionDetails>
            </Accordion>
          </CardContent>
      </>  
  );  
};

interface PropsProfessionOffersTable {
  selectedProfession: string;
}

const ProfessionOffersTable: React.FC<PropsProfessionOffersTable> = ({selectedProfession}) => {

  const apiUnauthenticatedService = useAPIUnauthenticatedService();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<OfferWithScope_UserWithoutId | null>(null);

  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = React.useState<'user.givenName' | 'location' | 'offeringFixedCashAmount'>('offeringFixedCashAmount');

  const handleSortRequest = (property: 'user.givenName' | 'location' | 'offeringFixedCashAmount') => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const descendingComparator = (a: OfferWithScope_UserWithoutId, b: OfferWithScope_UserWithoutId, orderBy: 'user.givenName' | 'location' | 'offeringFixedCashAmount') => {
    if (orderBy === 'user.givenName') {
      if (b.user.givenName < a.user.givenName) {
        return -1;
      }
      if (b.user.givenName > a.user.givenName) {
        return 1;
      }
    } else if (orderBy === 'location') {
      if (b.location < a.location) {
        return -1;
      }
      if (b.location > a.location) {
        return 1;
      }
    } else if (orderBy === 'offeringFixedCashAmount') {
      if (b.offeringFixedCashAmount < a.offeringFixedCashAmount) {
        return -1;
      }
      if (b.offeringFixedCashAmount > a.offeringFixedCashAmount) {
        return 1;
      }
    }
    return 0;
  };

  const getComparator = (order: 'asc' | 'desc', orderBy: 'user.givenName' | 'location' | 'offeringFixedCashAmount') => {
    return order === 'desc'
      ? (a: OfferWithScope_UserWithoutId, b: OfferWithScope_UserWithoutId) => descendingComparator(a, b, orderBy)
      : (a: OfferWithScope_UserWithoutId, b: OfferWithScope_UserWithoutId) => -descendingComparator(a, b, orderBy);
  };

  const { isLoading, isError, data, error } = useQuery(
    [CacheKeys.OFFERS_BY_PROFESSION_UNAUTHENTICATED, selectedProfession],
    () => apiUnauthenticatedService.getOffersByProfession(selectedProfession),
    { keepPreviousData: true }
  );

  let sortedOffers: OfferWithScope_UserWithoutId[] | undefined = undefined;

  if (data) {
    sortedOffers = [...data].sort(getComparator(order, orderBy));
  }

  const handleOpenDialog = (offer: OfferWithScope_UserWithoutId) => {
    setSelectedOffer(offer);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const { loginWithRedirect } = useAuth0();

  if (isLoading) return <div>Loading...</div>;
  if (isError || !data) return <div>An error occurred</div>;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{padding: '0px'}}>

                    <DisplayOfferInGridHeader
                    Name={<TableSortLabel
                            active={orderBy === 'user.givenName'}
                            direction={orderBy === 'user.givenName' ? order : 'asc'}
                            onClick={() => handleSortRequest('user.givenName')}
                          >
                            From
                          </TableSortLabel>}
                    Company={ <> </>  }
                    Location={<TableSortLabel
                          active={orderBy === 'location'}
                          direction={orderBy === 'location' ? order : 'asc'}
                          onClick={() => handleSortRequest('location')}
                        > Customer Location
                        </TableSortLabel>}
                    Offer={<>|&nbsp;<TableSortLabel
                          active={orderBy === 'offeringFixedCashAmount'}
                          direction={orderBy === 'offeringFixedCashAmount' ? order : 'asc'}
                          onClick={() => handleSortRequest('offeringFixedCashAmount')}
                        >
                          Offer
                        </TableSortLabel>|</>}
                    ActionButton={ <></>} />                 
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedOffers?.map((offer: OfferWithScope_UserWithoutId) => (
              <TableRow key={offer.id}>
                <TableCell colSpan={4}>     

                  <DisplayOfferInGridBody 
                    Name={(<Typography variant="body1">{offer.user.givenName} {offer.user.familyName}</Typography>)}
                    Company={ <> {!isDataEmpty(offer.user.companyName) && <Typography variant="body2">{offer.user.companyName}</Typography>} </>  }
                    Location={<>{offer.location === 'Not Specified' ? '' : offer.location }</>}
                    Offer={<>{offer.offeringFixedCashAmount > 0 && `£${offer.offeringFixedCashAmount}`} </>}
                    ActionButton={
                    <>
                      {!offer.user.isMe &&                      
                        <Button color="secondary" variant="contained" size='small' onClick={() => handleOpenDialog(offer)}>
                          Go
                        </Button>                   
                      }
                    </>} />                  

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedOffer && (
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle align='center'>            
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{margin:'30px 20px 0px 20px'}}>
              Please sign up and log in to make a referral
          </DialogContent>
          <DialogActions sx={{margin:'0px 0px 10px 0px'}}>
              <Box display="flex" justifyContent="center" width="100%">
                <Button
                  color='primary'
                  variant="contained"
                  onClick={() => loginWithRedirect({  
                    appState: { 
                      returnTo: window.location.href,
                      redirectUri: window.location.origin + window.location.pathname
                    }
                  })} >                
                  Log in
                </Button>
                <Button                      
                  color='primary'
                  onClick={() => loginWithRedirect({ 
                    authorizationParams: {
                      screen_hint: 'signup',
                    }, 
                    appState: {           
                        returnTo: window.location.href,
                        redirectUri: window.location.origin + window.location.pathname
                    }
                  })} >                
                  Sign Up
                </Button>
                {/* <Button onClick={handleCloseDialog} color="primary">
                  Cancel
                </Button>    */}
              </Box>        
            </DialogActions>
        </Dialog>
      )}
    </>
  );
};


export default ReferralsAppUnauthenticatedMakeReferral;










