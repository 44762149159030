// src/components/MyOffersDataTable.tsx
import React, { useState, useEffect } from 'react';
import { Box, Button, Container,Grid, Tab, Tabs, Card, CardHeader, CardContent, Typography} from '@mui/material';

import { useAPIAuthenticatedService } from '../../helpers/APICallsAuthenticated';

import { isDataEmpty, CacheKeys } from '../../helpers/DataStructures';

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import ReferralsAppOfferManagment from './ReferralsAppOfferManagement';

import { usersScreenSelection } from './ReferralsAppAuthenticated'
import RefferalsAppReferralsOut from './RefferalsAppReferralsOut';
import RefferalsAppReferralsIn from './ReferralsAppReferralsIn';

import { toast } from 'react-toastify';
import ReferralsAppAdmin from './ReferralsAppAdmin';
import { useAuth0 } from '@auth0/auth0-react';


const ReferralsAppHello: React.FC<{screenSelection: usersScreenSelection, setScreenSelection: (val: usersScreenSelection) => void}> = ({screenSelection, setScreenSelection}) => {
  
  const apiServiceInstance = useAPIAuthenticatedService();

  const queryClient = useQueryClient();

  const { user } = useAuth0();

  const [balance, setBalance] = useState(0);

  const { isLoading: isLoading_User, isError: isError_User, data: data_User, error: error_User } = useQuery(
    [CacheKeys.LOGGED_IN_USER], 
    apiServiceInstance.getAuthenticatedUser,    
    { keepPreviousData: true } 
  );
  
  const { isLoading: isLoading_UsersOffer, isError: isError_UsersOffer, data: data_UsersOffer, error: error_UsersOffer } = useQuery(
    [CacheKeys.LOGGED_IN_USERS_OFFER], 
    apiServiceInstance.getOffersForAuthenticatedUser,
    { keepPreviousData: true } 
  );
  
  const { isLoading: isLoading_Balance, isError: isError_Balance, data: data_Balance, error: error_Balance } = useQuery(
    [CacheKeys.REFERRALS_FROM_USER],
    apiServiceInstance.getUserReferralsOut,
    { 
      keepPreviousData: true,
      onSuccess: (data) => {
        const newBalance = data.reduce((total, referral) => {
          if (referral.status === 'succeeded' || referral.status === 'payoutRequested' ) {
            return total + referral.offer.offeringFixedCashAmount;
          }
          return total;
        }, 0);
        setBalance(newBalance);
      } 
    }
  );

  const setReferralsToPayoutRequestedMutation = useMutation(apiServiceInstance.setUserReferralsPayoutRequested, {
    onSuccess: () => {
      queryClient.invalidateQueries([CacheKeys.REFERRALS_FROM_USER]);
      toast.success("Balance Claimed");
    },
    onError: (error: any) => {
      toast.error(`An error occurred: ${error.response?.data?.message || error.message}`);
    }
  });  

  if ( isError_User || isError_UsersOffer ) {
    return <>error</>;
  }

  if (isLoading_User || isLoading_UsersOffer) {
    return null;
  }

  if(data_User && data_UsersOffer !== undefined ) {
    return (
      <>
      
      <Grid container direction="column" spacing={2}>

        <Grid item>
          <Card variant="outlined" style={{ margin: '0 auto' }}>
              <CardHeader 
                titleTypographyProps={{ variant:'h3'}}  
                title={`${data_User.givenName} ${data_User.familyName}`} 
                subheader={`${data_User.profession}${ !isDataEmpty(data_User.companyName) ? ` at ${data_User.companyName}` : ''}`}
                sx={{textAlign: 'center', backgroundColor: '#333', margin: '10px', padding: '5px' }}>
                
              </CardHeader>
              <CardContent sx={{textAlign: 'center'}}>

                <Box display="flex" justifyContent="center">
                  <Typography variant='h5' p={3}>Your Balance</Typography>
                  <Typography variant='bigPrimary' p={3}>£{balance}</Typography>
                </Box>  
                
                {balance > 0 && 
                  <Box sx={{marginBottom: '10px'}}>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      type="submit" 
                      size='large'
                      onClick={() => { setReferralsToPayoutRequestedMutation.mutate() }}>

                        Claim Balance

                    </Button>
                  </Box>
                }
              
              <Box>
                <Button 
                  variant="contained" 
                  color="secondary" 
                  type="submit" 
                  size='large'
                  onClick={() => { setScreenSelection(usersScreenSelection.makeReferral) }}
                >
                  Make a referral
                </Button>
              </Box>

              </CardContent>
          </Card>
        </Grid>
        <Grid item>
            <ReferralsAppOfferManagment/> 
        </Grid>
        <Grid item>
            <RefferalsAppReferralsIn/>
        </Grid>
        <Grid item>
            <RefferalsAppReferralsOut/>
        </Grid>

        { user && (user.sub == "auth0|642813a48864ae03763840a6" || user.sub == "auth0|6495c1e1fa9dddc06070f91d") &&
          <Grid item>
              <ReferralsAppAdmin setScreenSelection={setScreenSelection} />
          </Grid>
        }
      </Grid> 

      </>
    
    );
  }

  return <>no data...</>;

};

export default ReferralsAppHello;





