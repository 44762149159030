
export enum CacheKeys {
    LOGGED_IN_USER,
    LOGGED_IN_USERS_OFFER,
    OFFERS_BY_PROFESSION_AUTHENTICATED,
    OFFERS_BY_PROFESSION_UNAUTHENTICATED,
    REFERRALS_FROM_USER,
    REFERRALS_TO_USER
  }

  export const isDataEmpty = (str: string | null | undefined): boolean => {
    return str === null || str === undefined || str.trim() === '';
  };
  
  // export interface User_Info {
  //   givenName: string;
  //   familyName: string;
  //   profession: string;
  //   companyName: string | null;
  //   email: string;
  // }
  
  export interface User_WithoutId {    
    givenName: string;
    familyName: string;
    profession: string;
    companyName?: string;
    hasAgreedTsAndCs?: string;
    isMe: Boolean;
    email: string;
  }
  
  export interface UserAttributes {
    id: string;
    givenName: string;
    familyName: string;
    profession: string;
    companyName?: string;
    hasAgreedTsAndCs?: string;
    email: string;
  }
  
  export interface UserCreationAttributes_withoutemail {   
    givenName: string;
    familyName: string;
    profession: string;
    companyName?: string;  
  }
  
  export interface UserCreationAttributes extends UserCreationAttributes_withoutemail  {   
    email: string; 
  }  
  
  export interface UserForcedCreationAttributes extends UserCreationAttributes {
    createOffer: boolean;
    sendEmail: boolean;
  }
  
  export interface OfferWithScope_UserWithoutId {
    id: number;
    theOffer?: string;
    location: string;
    offeringFixedCashAmount: number;
    startDateTime: string;
    endDateTime?: string;
    user: User_WithoutId;
  }
  
  export interface OfferAttributes {
    id: number;
    userId: string;
    theOffer?: string;
    location: string;
    offeringFixedCashAmount: number;
    startDateTime: string;
    endDateTime?: string;
  }
  
  export interface OfferWithScope extends OfferAttributes {
    user: UserAttributes;
  }
  
  export interface ReferralAttributes {
    id: number;
    offerId: number;
    theReferralDetails?: string;
    theReferralName: string;
    theReferralContact: string;
    userId: string;
    created: string;
    status: string;
  }  
  
  
  export interface ReferralWithScope_UserWithoutId {
    id: number;
    offerId: number;
    theReferralDetails?: string;
    theReferralName: string;
    theReferralContact: string;
    created: string;
    status: string;
    offer: OfferWithScope_UserWithoutId;
    user: User_WithoutId;
  }  
  
  export interface ReferralWithScope extends ReferralAttributes {
    user: UserAttributes;
    offer: OfferWithScope;
  }  

  export interface ReferralCreationAttributes_FromUser{
 
    offerId: number;
    theReferralDetails?: string;
    theReferralName: string;
    theReferralContact: string;  
    customerConsent: boolean;
  }    
  
  export interface OfferCreationAttributes_FromUser { 
    theOffer?: string;
    location: string;
    offeringFixedCashAmount: number;
  }
  
  