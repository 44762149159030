import React, { ErrorInfo, ReactNode } from 'react';
import { Box } from '@mui/material';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false
  };

  private errorMessage: string = '';

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    logErrorToMyService(error, errorInfo);

    this.errorMessage = error.name + ' : ' + error.message;
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box sx={{textAlign:'left'}}>
        <h1>Error... Please Refresh the web page.</h1>
        <h3>{this.errorMessage}</h3>
      </Box>)
    }

    return this.props.children; 
  }
}

const logErrorToMyService = (error: Error, errorInfo: ErrorInfo) => {
  // This can be replaced with a logging service of your choice
  console.log(error, errorInfo);  
}

export default ErrorBoundary;
