import React, { useState, useEffect } from "react";

import { useAuth0 } from '@auth0/auth0-react';

import { Grid, Box, Card, CardContent, Typography, Modal, Accordion, AccordionSummary, AccordionDetails, } from '@mui/material';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import LoadingButton from '@mui/lab/LoadingButton';
import { BigGreenSpacedTypography, EasyReadTypography, BootstrapInput } from "../components/CustomMUI";

//import { SensibleLoadingRequest } from '../helpers/SensibleLoadingRequests';
//import MuiAlert from '@mui/material/Alert';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'
import axios from 'axios';

import { toast } from 'react-toastify';

import { BoxProps , styled } from '@mui/system';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const TextPage: React.FC<TextToTypographyProps> = ({ filename }) => {
  
  const theme = useTheme();
  return (
    <>
    <Card sx={{margin: '50px'}}>
      <CardContent sx={{padding: {sx: '20px', md: '100px'}}}>
        <TextToTypography filename={ filename }></TextToTypography>
      </CardContent>
    </Card>
    </>
  );
}

interface TextToTypographyProps {
  filename: string;
}

export const TextToTypography: React.FC<TextToTypographyProps> = ({ filename }) => {
  const [text, setText] = useState<string>('');

  useEffect(() => {
    fetch(filename)
      .then(response => response.text())
      .then(data => setText(data));
  }, [filename]);

  if (!text) {
    return <div>Loading...</div>;
  }

  // Split the text into paragraphs by splitting on double line breaks
  const paragraphs = text.split(/\r?\n\r?\n/);

  return (
    <>
      <Box sx={{maxWidth: '726px'}}>
     
      {paragraphs.map((paragraph, index) => {
        const isFirstCharDigit = /^\d/.test(paragraph); // check if first character is a digit
        const hasDecimal = /^\d+\.\d/.test(paragraph); // check if it begins with a decimal number

        if (index === 0 || (isFirstCharDigit && !hasDecimal)) {
          return (
            <BigGreenSpacedTypography key={index} gutterBottom>
              {paragraph}
            </BigGreenSpacedTypography>
          );
        } else if (hasDecimal) {
          return (
            <Typography key={index} variant="h6" gutterBottom sx={{marginBottom: '20px'}}>
              {paragraph}
            </Typography>
          );
        } else {
          return (
            <Typography variant="body1" key={index} gutterBottom sx={{marginBottom: '40px', textAlign: 'justify'}} >
              {paragraph}
            </Typography>
          );
        }
      })}

      </Box>
    </>
  );
};

export default TextPage;

