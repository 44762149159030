import React  from 'react';
import { Card, Button } from '@mui/material';

import { InHouseCardHeader, InHouseCardContent } from '../../components/CustomMUI';

import { usersScreenSelection } from './ReferralsAppAuthenticated';


interface ReferralsAppAdminProps {

    setScreenSelection: (val: usersScreenSelection) => void
}

const ReferralsAppAdmin: React.FC<ReferralsAppAdminProps> = ({setScreenSelection}) => {
 
  return (

    <>
    
      <Card variant="outlined" title='dd' style={{ margin: '0 auto' }}>
       
              <InHouseCardHeader title='Admin'  />  
              <InHouseCardContent style={{ textAlign: 'center' }} >              
                <Button type="submit" onClick={() => { setScreenSelection(usersScreenSelection.forceUser) }}>
                    Add user
                </Button>
              </InHouseCardContent>               
                          
      </Card>

    
    </>    
  );
};

export default ReferralsAppAdmin;
