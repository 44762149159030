import React, { useState } from 'react';
import { Card, Grid, Box, TextField, Button, CardContent, Typography } from '@mui/material';
import { MailToTeam } from '../helpers/MailToTeam';
import { toast } from 'react-toastify';

import { InHouseCardHeader, InHouseCardContent } from '../components/CustomMUI';

const Contact= () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const text = `Name: ${name}\nEmail: ${email}\nMessage: ${message}`;

    try {
      await MailToTeam(text);
      setName('');
      setEmail('');
      setMessage('');
      toast.success('Message Sent');
    }
    catch (err) {
        toast.error('Could not send message');
    }
  };

  return (
    <Card>
    <InHouseCardHeader title='Contact Us'  />  
      <InHouseCardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField 
                label="Name" 
                value={name} 
                onChange={(e) => setName(e.target.value)}
                fullWidth 
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                label="Email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
                fullWidth 
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                label="Message" 
                value={message} 
                onChange={(e) => setMessage(e.target.value)}
                multiline 
                rows={4} 
                fullWidth 
              />
            </Grid>
            <Grid item xs={12} sx={{textAlign: 'center' }}>
              <Box mt={2}>
                <Button 
                  type="submit" 
                  variant="contained" 
                  color="primary"                  
                >
                  Send
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </InHouseCardContent>
    </Card>
  );
};

export default Contact
