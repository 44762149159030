import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider, CacheLocation } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);



const providerConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN!,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
  authorizationParams: {
    redirect_uri: `${window.location.origin}/dashboard`,
    ...( { audience: process.env.REACT_APP_AUTH0_AUDIENCE! } ),
  },
  useRefreshTokens: true,  
  cacheLocation: 'localstorage' as 'memory' | 'localstorage',
  responseType: 'token id_token',
  scope: 'openid profile email offline_access'
};

root.render(
  <React.StrictMode>
    <Auth0Provider {...providerConfig}>
    <App />
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
