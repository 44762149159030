import React, { useState } from 'react';
import { Grid, Box, IconButton, Typography } from '@mui/material';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Cancel from '@mui/icons-material/CancelOutlined';

interface DisplayOfferInGridProps {
    Name: JSX.Element;
    Company: JSX.Element;
    Location: JSX.Element;
    Offer: JSX.Element;
    ActionButton: JSX.Element;
  }

  export const DisplayOfferInGridBody: React.FC<DisplayOfferInGridProps> = ({ Name, Company, Location, Offer, ActionButton }) => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Grid container spacing={1}>                        
            <Grid item xs={12}>
              <Typography variant='bigPrimary'> {Name}</Typography>
              {Company} 
            </Grid>
  
            <Grid item xs={12}>
              {Location}
            </Grid>
          </Grid>
        </Grid>
  
        <Grid item xs={3}>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">                               
          <Typography variant='h5'> {Offer} </Typography>                               
          </Box> 
        </Grid>
  
        <Grid item xs={3}>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            {ActionButton}
          </Box>                        
        </Grid>
      </Grid>
    )
  }

export const DisplayOfferInGridHeader: React.FC<DisplayOfferInGridProps> = ({ Name, Company, Location, Offer, ActionButton }) => {
    
  const [isGridVisible, setGridVisible] = useState(false);

  const handleVisibilityToggle = () => {
    setGridVisible(prev => !prev);
  };

  return (
    <>
     
        <Grid container spacing={0} >
          <Grid item xs={6} style={{ padding: isGridVisible ? '10px' : '0px' }}>
          {isGridVisible && (
            <Grid container spacing={0}>                        
            <Grid item xs={12}>
              {Name}
              {Company} 
            </Grid>

            <Grid item xs={12}>
              {Location}
            </Grid> 
            </Grid>
          )} 
          </Grid>

          <Grid item xs={3} >
          {isGridVisible && (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">                               
              {Offer}                            
            </Box> 
          )}
          </Grid>

          <Grid item xs={3} style={{ paddingRight: '16px' }}>
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">           
                <IconButton onClick={handleVisibilityToggle}>
                {!isGridVisible ? <><ArrowDownward /><ArrowUpward /></> : <Cancel /> }
                </IconButton>        
            </Box>                        
          </Grid>
        </Grid>    
    </>
  );
}
