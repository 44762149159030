import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import PuffLoader  from 'react-spinners/PuffLoader';
import { useTheme,  } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import { motion, usePresence } from 'framer-motion';

interface BusyProps{
    message: string;
}

const Busy: React.FC<BusyProps>= ({message}) => {  
    
const [isPresent, safeToRemove] = usePresence();
const theme = useTheme();
  
useEffect(() => {
    if(safeToRemove) safeToRemove();   
    
  }, [safeToRemove]);

  return (
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '500px'
       }}>
        <PuffLoader 
          loading={true}
          color={theme.palette.primary.main}        
        />
        <Typography variant='h5'>{message}</Typography>
      </div>
  );
};

export default Busy;
