import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TextField, Grid, Button, FormControlLabel, Checkbox, Typography, FormHelperText } from '@mui/material';
import { toast } from 'react-toastify';
import { useAPIAuthenticatedService } from '../helpers/APICallsAuthenticated';
import { CacheKeys, ReferralCreationAttributes_FromUser } from '../helpers/DataStructures';

interface CreateReferralFormProps {
  offerId: number; // Assuming the offer id is provided as a prop
  closeDialog: () => void;
}

const CreateReferralForm: React.FC<CreateReferralFormProps> = ({ offerId, closeDialog }) => {
  const apiServiceInstance = useAPIAuthenticatedService();

  const { handleSubmit, control, formState: { errors }} = useForm<ReferralCreationAttributes_FromUser>();

  const queryClient = useQueryClient();

  const { mutateAsync, isError, error } = 
    useMutation([CacheKeys.REFERRALS_FROM_USER], apiServiceInstance.createReferral,
    {
        onSuccess: () => {
            queryClient.invalidateQueries([CacheKeys.REFERRALS_FROM_USER]);
            toast.success("Referral Sent");
            closeDialog();
        },
        onError: (error: any) => {
            toast.error(`An error occurred: ${error.response?.data?.message || error.message}`);
        }
    },
  );

  const onSubmit = (data: ReferralCreationAttributes_FromUser ) => {
    if (!data.customerConsent) {
      toast.error("You must get permission from the customer to share their contact details");
      return;
    }

    mutateAsync({ ...data, offerId});
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={2}>       

        <Grid item xs={12}>
            <Controller
                name="theReferralName"
                control={control}
                rules={{ required: 'Referral name is required' }}
                render={({ field }) => <TextField {...field} label="Referral Name" error={Boolean(errors.theReferralName)} 
                helperText={errors.theReferralName?.message} fullWidth />}
            />
        </Grid>

        <Grid item xs={12}>
            <Controller
                name="theReferralContact"
                control={control}
                rules={{ required: 'Referral contact is required' }}
                render={({ field }) => <TextField {...field} label="Referral Contact" error={Boolean(errors.theReferralContact)} 
                helperText={errors.theReferralContact?.message} fullWidth />}
            />
        </Grid>

        <Grid item xs={12}>
            <Controller
                name="theReferralDetails"
                control={control} 
                render={({ field }) => 
                <TextField multiline rows={3} {...field} label="Referral Details" fullWidth />}
            />
        </Grid>

        <Grid item xs={12}>
        <Controller 
            name="customerConsent"
            control={control}
            rules={{ required: 'You must get permission from the customer to share their contact details' }}
            defaultValue={false}
            render={({ field }) => 
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={
                  <Typography variant="body2" sx={{ textAlign:"start"}}>
                    I have asked the customer, and they have consented, to share their contact details in order to make the referral
                  </Typography>
                }
                labelPlacement="end"
              />
            }
            />
            {errors.customerConsent && <FormHelperText error>{errors.customerConsent.message}</FormHelperText>}
        </Grid>

        <Grid item xs={12}>
            <Button type="submit">Create Referral</Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateReferralForm;
